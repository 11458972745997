<template>
  <div id="app" ref="vantaRef">
    <div id="wrapper" style="z-index: 10;">
      <img id="logo" alt="SynRG logo" src="./assets/logo.svg">
      <Terminal style="z-index: 1;" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Terminal from './components/Terminal.vue'
import NET from 'vanta/src/vanta.net'

export default {
  name: 'App',
  components: {
    Footer,
    Terminal
  },
  created() {
    document.title = 'SynRG Consulting'
  },
   mounted() {
    this.vantaEffect = NET({
      el: this.$refs.vantaRef,
      backgroundColor: '#001122',
      color: '#68682f',
      backgroundAlpha: 0.1,
      points: 5.00,
      maxDistance: 21.00,
      spacing: 20.00
    })
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');
#app {
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: cornflowerblue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1080px;
  /* margin-top: 20vh; */
}

#wrapper {
  margin-top: 20vh;
}

body {
  box-sizing: border-box;
  margin: 0;
  background-color: #001122;
}

#logo {
  margin-bottom: 50px;
  width: 33vw;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  
  #logo {
    width: 90vw;
  } 
  
  #wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }

}
</style>
