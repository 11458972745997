<template>
  <div id="footer">
      Syn R G Consulting Inc. &copy; {{year}}
  </div>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        year(){
            return new Date().getFullYear()
        }
    }

}
</script>

<style>
#footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 15px;
}
</style>